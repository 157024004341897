'use strict';

var app = angular.module('profile', ['ui.bootstrap', 'ngRoute', 'ngCookies', 'angular.filter', 'profile.config', 'angular-bind-html-compile']);

app.config(["$routeProvider", "$httpProvider", "$locationProvider", "$qProvider", function($routeProvider, $httpProvider, $locationProvider, $qProvider){
   $routeProvider
       .when ('/create',
           {
               controller: 'CreateProfileController',
               templateUrl: 'partials/CreateProfile.html'
           })
       .when ('/unsubscribe',
           {
               controller: 'UnsubscribeController',
               templateUrl: 'partials/Unsubscribe.html'
           })
       .when ('/subscribe',
           {
               controller: 'UnAuthSubscribeController',
               templateUrl: 'partials/UnAuthSubscribe.html'
           })
       .when ('/forgot-password',
           {
               controller: 'ForgotPasswordController',
               templateUrl: 'partials/ForgotPassword.html'
           })
       .when('/reset-password',
           {
               controller: 'ResetPasswordController',
               templateUrl: 'partials/ResetPassword.html'
           })
       .when('/finish-activation/:system?',
           {
               controller: 'FinishActivationController',
               templateUrl: 'partials/FinishActivation.html'
           })
	   .when ('/optin',
		   {
			   controller: 'OptinController',
			   templateUrl: 'partials/Optin.html'
		   })
	   .when ('/optin-verify',
		   {
			   controller: 'OptinVerifyController',
			   templateUrl: 'partials/OptinVerify.html'
		   })
	   .when ('/optout',
		   {
			   controller: 'OptoutController',
			   templateUrl: 'partials/Optout.html'
		   })
	   .when ('/error',
           {
               controller: 'ErrorController',
               templateUrl: 'partials/Error.html'
           })
       .otherwise({ redirectTo:'/create' });

    $httpProvider.interceptors.push('ProfileClientInterceptor');

	$locationProvider.hashPrefix('');

	$qProvider.errorOnUnhandledRejections(false);
}]);

app.run(["$http", "APP_KEY", "$rootScope", "ProfileUtils", "ProfileErrorHandler", "GTM_TRACKING_CODE", function ($http, APP_KEY, $rootScope, ProfileUtils, ProfileErrorHandler, GTM_TRACKING_CODE) {
    //utility functions bound to root scope for access in partials
    $rootScope.ProfileUtils = ProfileUtils;
    $rootScope.ProfileErrorHandler = ProfileErrorHandler;

    //common headers
    $http.defaults.headers.common['X-SASProfile-AppKey'] = APP_KEY;
    $http.defaults.headers.common.Accept = "application/vnd.sas.profile-v3+json";
    $http.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    $http.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';

    //initialize GTM tracking
    initGTM(GTM_TRACKING_CODE);
}]);

app
    .constant('PROFILE_ERRORS', {
        INVALID_PARAMETER_ERROR: 400,
        UNAUTHORIZED_ERROR: 401,
        FORBIDDEN_ERROR: 403,
        NOT_FOUND_ERROR: 404,
        CONFLICT_ERROR: 409,
        UNPROCESSABLE_ENTITY_ERROR: 422,
        TOO_MANY_REQUESTS_ERROR: 429,
        INTERNAL_SERVER_ERROR: 500,
        SERVICE_UNAVAILABLE_ERROR: 503
    });

app
    .constant('PROFILE_CONSTANTS', {
        SAS_URL: 'https://www.sas.com',
        JMP_URL: 'https://www.jmp.com',
        INFLUITIVE_URL: 'https://explorers.sas.com'
    });
app.factory('OktaClient', ["OKTA_ORG_URL", "AUTH_CLIENT_ID", "AUTH_ISSUER_URL", "AUTH_REDIRECT_URI", "$q", "$window", function (OKTA_ORG_URL, AUTH_CLIENT_ID, AUTH_ISSUER_URL, AUTH_REDIRECT_URI, $q, $window) {
    'use strict';

    var authClient = new OktaAuth({
        issuer: AUTH_ISSUER_URL,
        clientId: AUTH_CLIENT_ID,
        redirectUri: AUTH_REDIRECT_URI
    });

    return {
        user: {
            getInfo: function () {
                return $q(function (resolve, reject) {
                    authClient.session.exists()
                        .then(function (exists) {
                            if (!exists) {
                                reject();
                                return;
                            }

                            authClient.session.get()
                                .then(function (session) {
                                    session.user()
                                        .then(function (user) {
                                            resolve(user);
                                        })
                                        .catch(function (err) {
                                            reject(err);
                                        });
                                })
                                .catch(function (err) {
                                    reject(err);
                                });
                        });
                });
            },
            signOut: function () {
                return $q(function (resolve, reject) {
                    authClient.signOut()
                        .then(function () {

                        })
                        .catch(function (err) {
                            console.error(err);
                        })
                });
            }
        },
        getAccessTokenOrGoToSignInPage: function () {
            if (authClient.isLoginRedirect()) {
                // callback flow
                try {
                    authClient.handleRedirect(authClient.getOriginalUri());

                } catch (e) {
                    console.log(e);
                }
            } else {
                authClient.setOriginalUri($window.location.href);
                authClient.signInWithRedirect();
            }
        },
        authClient: authClient
    };
}]);

app.factory('ProfileClient', ["$http", "$httpParamSerializerJQLike", "BASE_URL", "ProfileErrorHandler", "$q", function ($http, $httpParamSerializerJQLike, BASE_URL, ProfileErrorHandler, $q) {
    'use strict';

    var SERVICES_EXT_URL = BASE_URL + '/profile/services/ext';

    return {
        user: {
            create: function (user) {
                return $http({
                    method: 'POST',
                    url: SERVICES_EXT_URL + '/users',
                    data: $httpParamSerializerJQLike(user)
                });
            },
            update: function (user) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_EXT_URL + '/users/~',
                    data: $httpParamSerializerJQLike(user)
                });
            },
            read: function () {
                return $http({
                    method: 'GET',
                    url: SERVICES_EXT_URL + '/users/~'
                }).then(function(response){ // Success
                        return response;
                    },
                    function(response){ // Error
	                    ProfileErrorHandler.handleUserNotFoundError(response);
	                    $q.reject(response.data);
                    }
                );
            },
            marketing: {
                update: function (marketing) {
                    return $http({
                        method: 'PUT',
                        url: SERVICES_EXT_URL + '/users/~/marketing',
                        data: $httpParamSerializerJQLike(marketing)
                    });
                },
                read: function () {
                    return $http({
                        method: 'GET',
                        url: SERVICES_EXT_URL + '/users/~/marketing'
                    });
                }
            },
            address: {
                update: function (address) {
                    return $http({
                        method: 'PUT',
                        url: SERVICES_EXT_URL + '/users/~/addresses/0',
                        data: $httpParamSerializerJQLike(address)
                    });
                },
                read: function () {
                    return $http({
                        method: 'GET',
                        url: SERVICES_EXT_URL + '/users/~/addresses'
                    });
                }
            },
            phone: {
                read: function () {
                    return $http({
                        method: 'GET',
                        url: SERVICES_EXT_URL + '/users/~/phones'
                    });
                },
                update: function (phone) {
                    return $http({
                        method: 'PUT',
                        url: SERVICES_EXT_URL + '/users/~/phones/0',
                        data: $httpParamSerializerJQLike(phone)
                    });
                }
            },
            password: {
                update: function (password) {
                    return $http({
                        method: 'PUT',
                        url: SERVICES_EXT_URL + '/users/~/password',
                        data: $httpParamSerializerJQLike(password)
                    });
                },
                getPolicy: function (email) {
                    return $http({
                        method: 'get',
                        url: SERVICES_EXT_URL + '/passwordpolicy/' + email
                    });
                }
            },
            subscription: {
                read: function () {
                    return $http({
                        method: 'GET',
                        url: SERVICES_EXT_URL + '/users/~/subscriptions'
                    });
                },
                add: function(subscriptionId) {
                    return $http({
                        method: 'PUT',
                        url: SERVICES_EXT_URL + '/users/~/subscriptions/' + subscriptionId
                    });
                },
                remove: function(subscriptionId) {
                    return $http({
                        method: 'DELETE',
                        url: SERVICES_EXT_URL + '/users/~/subscriptions/' + subscriptionId
                    });
                }
            },
            optin: {
                update: function (optin) {
                    return $http({
                        method: 'PUT',
                        url: SERVICES_EXT_URL + '/users/~/optin',
                        data: $httpParamSerializerJQLike(optin)
                    });
                },
                read: function () {
                    return $http({
                        method: 'GET',
                        url: SERVICES_EXT_URL + '/users/~/optin'
                    });
                }
            },
            source: {
                read: function () {
                    return $http({
                        method: 'GET',
                        url: SERVICES_EXT_URL + '/users/~/source'
                    });
                }
            }
        },
        constants: {
            getAffiliations: function () {
                return $http.get(SERVICES_EXT_URL + '/constants/affiliations');
            },
	        getCompanies: function (searchTerm,country,partner) {
		        return $http({
			        url: SERVICES_EXT_URL + '/constants/companies',
			        method: 'GET',
			        params: {term: searchTerm, limit: 5, country: country, partner: partner}
		        }).then(function(response) {
				        return response;
			        },
			        function(response) {
				        // Companies service is not HA, so we can live with no autocomplete being available.

				        return [];
			        });
	        },
            getCountries: function () {
                return $http.get(SERVICES_EXT_URL + '/constants/countries');
            },
            getExpertise: function () {
                return $http.get(SERVICES_EXT_URL + '/constants/expertise');
            },
            getLanguages: function () {
                return $http.get(SERVICES_EXT_URL + '/constants/languages');
            },
            getSalutations: function () {
                return $http.get(SERVICES_EXT_URL + '/constants/salutations');
            },
            getJobFunctions: function () {
                return $http.get(SERVICES_EXT_URL + '/constants/jobFunctions');
            },
            getIndustries: function () {
                return $http.get(SERVICES_EXT_URL + '/constants/industries');
            },
            getTranslatedProperties: function () {
                return $http.get(SERVICES_EXT_URL + '/constants/properties/' + Array.prototype.slice.call(arguments).join(','))
                    .then(function(response){ // Success
                            return response;
                        },
                        function(response){ // Error
	                        ProfileErrorHandler.goToErrorPage(response);
	                        $q.reject(response.data);
                        }
                    );
            }
        },
        subscriptions:{
            all: function(country, affiliation) {
                if (affiliation !== undefined) {
                    return $http.get(SERVICES_EXT_URL + '/subscriptions/all/' + country + '/' + affiliation);
                } else {
                    return $http.get(SERVICES_EXT_URL + '/subscriptions/all/' + country );
                }
            },
            forSubcode: function(subcode) {
                return $http.get(SERVICES_EXT_URL + '/subscriptions/' + subcode );
            },
            unauthUnsubscribe: function (emailAddress,subcode,user) {
                return $http({
                    method: 'DELETE',
                    url: SERVICES_EXT_URL + '/subscriptions/anonymous/' + emailAddress + '?subscriptionCode=' + subcode,
                    data: $httpParamSerializerJQLike(user)
                });
            },
            unauthSubscribe: function (emailAddress,subcode,user) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_EXT_URL + '/subscriptions/anonymous/' + emailAddress + '?subscriptionCode=' + subcode,
                    data: $httpParamSerializerJQLike(user)
                });
            }
        },
        requests:{
            changeEmail: function(emailAddress){
                return $http.post(SERVICES_EXT_URL + '/requests/changeEmail', $httpParamSerializerJQLike(emailAddress));
            },
            finishChangeEmail: function(token) {
                return $http.put(SERVICES_EXT_URL + '/requests/changeEmail/' + token);
            },
            finishActivation: function(token, password) {
                return $http.put(SERVICES_EXT_URL + '/requests/activation/' + token,
                    $httpParamSerializerJQLike(password));
            },
            getActivation: function(token) {
                return $http.get(SERVICES_EXT_URL + '/requests/activation/' + token);
            },
            finishResetPassword: function(requestId, password) {
                return $http.put(SERVICES_EXT_URL + '/requests/resetPassword/' + requestId,
                    $httpParamSerializerJQLike(password));
            },
            resetPassword: function(request) {
                return $http.post(SERVICES_EXT_URL + '/requests/resetPassword', $httpParamSerializerJQLike(request));
            },
            getResetPassword: function (token) {
                return $http.get(SERVICES_EXT_URL + '/requests/resetPassword/' + token);
            }
        },
        validation: {
            returnUrl: function(returnUrl) {
                return $http.get(SERVICES_EXT_URL + '/validationStatus/returnUrl?value=' + returnUrl);
            },
	        optinOrganization: function(optinOrganization) {
		        return $http.get(SERVICES_EXT_URL + '/validationStatus/optinOrganization?value=' + optinOrganization);
	        }
        },
	    emailpreferences: {
		    optin:function(emailAddress,optin){
			    return $http({
				    method:'PUT',
				    url:SERVICES_EXT_URL + '/emailpreferences/' + emailAddress + '/optin',
				    data:$httpParamSerializerJQLike(optin)
			    });
		    },
		    optout:function(emailAddress,optout){
			    return $http({
				    method:'PUT',
				    url:SERVICES_EXT_URL + '/emailpreferences/' + emailAddress + '/optout',
				    data:$httpParamSerializerJQLike(optout)
			    });
		    },
		    optinVerify:function(token){
			    return $http({
				    method:'PUT',
				    url:SERVICES_EXT_URL + '/emailpreferences/' + token + '/verify'
			    });
		    },
		    getEmailAndOrganization:function(token){
			    return $http({
				    method:'GET',
				    url:SERVICES_EXT_URL + '/emailpreferences/token/' + token
			    });
		    },
	    },
	    gmsToken: {
		    getEmail: function (gmsToken) {
			    return $http({
				    method: 'GET',
				    url: SERVICES_EXT_URL + "/gmsToken/" + gmsToken + '/email'
			    }).then(function(response){ // Success
					    return response;
				    },
				    function(){ // Error
					    // Ignore
				    }
			    );
		    }
	    },
	    error: {
		    reportError: function (errorId) {
			    return $http({
				    method: 'GET',
				    url: BASE_URL + "/profile-whoops/" + errorId + '.html'
			    });
		    }
	    }
    };
}]);

app.factory('QueryParamParser', ["$http", "$location", "BASE_URL", "ProfileClient", "ProfileUtils", "$rootScope", function ($http, $location, BASE_URL, ProfileClient, ProfileUtils, $rootScope) {
    'use strict';

    var self = {
        // TODO: validate locale?

        getLocale: function(){
	        var locale = self.getQueryParams()['locale'];

	        if(angular.isArray(locale)) {
		        locale = locale[0];
	        }

	        return locale;
        },

        applyLocaleIfPresent: function(callback) {
            ProfileUtils.changeLanguage(self.getLocale(), callback);
        },

        getEmail: function() {
	        return self.getQueryParamsLowerCase()['email'];
        },

        getCallout: function(callback) {
            var callout = self.getQueryParams()['callout'];
            if (callout) {
                callback(callout);
            }
        },

        getQueryParams: function() {
            var params = $location.search();

            var parseQueryString = function (queryString) {
                var params = {}, queries, temp, i, l;
                queryString = queryString.substring(queryString.indexOf("?") + 1, queryString.length);
                // Split into key/value pairs
                queries = queryString.split("&");
                // Convert the array of strings into an object
                for (i = 0, l = queries.length; i < l; i++) {
                    temp = queries[i].split('=');
                    params[temp[0]] = temp[1];
                }
                return params;
            };

            if (!params) {
                params = {};
            }

            var referrer = self.getReferrer();
            if (referrer) {
                try {
                    var referrerParams = parseQueryString(referrer);

                    for (var paramName in referrerParams) {
                        if (referrerParams.hasOwnProperty(paramName) && !params[paramName]) {
                            // console.log("pushing param: " + paramName + ":" + referrerParams[paramName]);
                            params[paramName] = referrerParams[paramName];
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            }

            return params;
        },

	    getQueryParamsLowerCase: function() {
		    var ciParams = {};
		    angular.forEach(self.getQueryParams(), function(value, key) {
			    ciParams[key.toLowerCase()] = value;
		    });
		    return ciParams;
	    },

        getReturnURL: function(callback, defaultValue, parameterName, validate) {
            var returnUrl;
            var queryParamsLowerCase = self.getQueryParamsLowerCase();

            // TODO: It would be nice to use default values here since only IE doesn't support them and we don't support IE any longer, but they make usemin crash…
            if (parameterName === undefined || parameterName === null) {
                parameterName = "returnurl";
            }
            if (validate === undefined || validate === null) {
                validate = true;
            }

            var fromUri = queryParamsLowerCase["fromuri"];
            var parameterUrl = queryParamsLowerCase[parameterName];
            if (fromUri && fromUri.contains("influitive")){
                returnUrl = fromUri;
            } else if (parameterUrl) {
                returnUrl = parameterUrl;
            } else if (fromUri) {
                returnUrl = fromUri;
            } else  {
                returnUrl = self.getReferrer();
            }

            if (!returnUrl) {
               // The global default is to just use the environment's base URL or the default value.
               callback(defaultValue || BASE_URL);
               return;
            }

            if (!validate) {
                callback(returnUrl);
            } else {
                // Validate the return URL (i.e., make sure it's a valid URL and points to *.sas.com or *.jmp.com).
                ProfileClient.validation.returnUrl(returnUrl)
                    .then(function(response) { // Success
                            if (response.data === "true") {
                                callback(returnUrl);
                            } else {
                                callback(defaultValue || BASE_URL);
                            }
                        },
                        function() { // Error
                            callback(defaultValue || BASE_URL);
                        }
                    );
            }
        },

        getLoginPageTheme: function(callback) {
            self.getReturnURL(function(returnUrl) {
                var a = document.createElement("a");

                a.href = returnUrl;
                var returnUrlHostname = a.hostname.toLowerCase().trim();
                var returnUrlPath = a.pathname.toLowerCase().trim();

                a.href = self.getReferrer();
                var referrerHostname = a.hostname.toLowerCase().trim();

                if (
                    self.getQueryParamsLowerCase()["jmp"] === "true" ||
                    returnUrlHostname === "jmp.com" ||
                    returnUrlHostname.endsWith(".jmp.com") ||
                    referrerHostname === "jmp.com" ||
                    referrerHostname.endsWith(".jmp.com") ||
                    returnUrlPath.startsWith("/jmpware/jmptrial") ||
                    returnUrlPath.startsWith("/jmpstore")
                ) {
                    callback("jmp");
                } else if (returnUrlPath.contains("influitive")) {
                    callback("influitive");
                } else {
                    callback("sas");
                }
            }, null, null, false);
        },

        getReferrer: function() {
            return $rootScope.referrer;
        },

        getToken: function() {
            return self.getQueryParams()['token'];
        }
    };

    return self;
}]);
app.factory('ProfileUtils', ["$sce", "PROFILE_ERRORS", "$http", function ($sce, PROFILE_ERRORS, $http) {
    'use strict';

    return {
        /**
         * Trusts server output as HTML code.
         *
         * @param htmlCode the HTML code to trust
         * @returns trusted HTML code
         */
        trustAsHtml: function (htmlCode) {
            return $sce.trustAsHtml(htmlCode);
        },

        /**
         * Gets the states for a selected country.
         *
         * @param countries the list of countries to select from
         * @param selectedCountry the selected country
         * @returns Array of states for the selected country
         */
        getCountryStates: function(countries, selectedCountry) {
            var states = [];

            for (var i = 0; i < countries.length; i++) {
                var country = countries[i];
                if (country.code === selectedCountry && country.states && country.states.length > 0) {
                    states = country.states;
                    break;
                }
            }

            return states;
        },

        /**
         * Opens pop up windows. This function is an outside library and needs to be exposed
         * here for access in angular directives.
         */
        openWindow: openWindow,

        /**
         * Checks the returnUrl validation status of a response
         *
         * @param data the HTTP response data
         * @param status the HTTP response status
         * @returns Boolean, true if the returnUrl validation failed
         */
        didReturnUrlValidationFail: function(data, status) {
            if(status === PROFILE_ERRORS.INVALID_PARAMETER_ERROR) {
                if (angular.isArray(data)) {
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].item === "returnUrl") {
                            return true;
                        }
                    }
                } else {
                    if (data.item === "returnUrl") {
                        return true;
                    }
                }
            }
            return false;
        },

        changeLanguage: function(locale, callback) {
            if (locale) {
                locale = locale.replace('_', '-');
                $http.defaults.headers.common['X-SASProfile-Accept-Language-Override'] = locale;

                if (callback) {
                    callback(locale);
                }
            }
        }
    };
}]);
app.factory('ProfileErrorHandler', ["$window", "PROFILE_ERRORS", "$location", "BASE_URL", "$rootScope", "OktaClient", function ($window, PROFILE_ERRORS, $location, BASE_URL, $rootScope, OktaClient) {
    'use strict';

    return {
        /**
         * Handles 401/Unauthorized (which actually means "unauthenticated")
         *
         * @param response the response
         */
        handleUnauthorizedError: function (response) {
            if(response.status === PROFILE_ERRORS.UNAUTHORIZED_ERROR && response.data.item === 'bearerToken') {
                OktaClient.getAccessTokenOrGoToSignInPage();
            } else if(response.status === PROFILE_ERRORS.UNAUTHORIZED_ERROR) {
                this.goToErrorPage(response);
            }
        },

        /**
         * Handles 403/Forbidden (meaning that the user is authenticated but doesn't have permissions to perform the requested action)
         *
         * @param response the response
         */
        handleInvalidAppKeyError: function(response) {
            if(response.status === PROFILE_ERRORS.FORBIDDEN_ERROR) {
                this.goToErrorPage(response);
            }
        },

        /**
         * Handles server error, e.g. when the server is down.
         *
         * @param response the response
         */
        handleServerError: function(response) {
            var serverError = false;

            try {
                angular.fromJson(response.data);
            }
            catch(e) {
                serverError = true;
            }

            if(!response.status) {
                serverError = true;
            }
            else if(response.status === PROFILE_ERRORS.INTERNAL_SERVER_ERROR) {
                serverError = true;
            }

            if(serverError) {
                this.goToErrorPage(response);
            }
        },

        /**
         * Handles user not found error.
         *
         * @param response the response
         */
        handleUserNotFoundError: function(response) {
            if(response.status === PROFILE_ERRORS.NOT_FOUND_ERROR && response.data && response.data.item && response.data.item == 'user') {
                this.goToErrorPage(response);
            }
        },

        /**
         * Handles all application-level errors. This function is automatically applied
         * to failed responses by the ProfileClientInterceptor.
         *
         * @param response the response
         */
        handleApplicationErrors: function(response) {
            this.handleApplicationErrorsExceptUnauthorized(response);
            this.handleUnauthorizedError(response);
        },

        /**
         * Handles all application-level errors except for unauthorized. This function is handles cases
         * where we need to handle most errors but exclude unauthorized errors, for example to check
         * if a user is logged in from the non-authorized pages.
         *
         * @param response the response
         */
        handleApplicationErrorsExceptUnauthorized: function(response) {
            this.handleServerError(response);
            this.handleInvalidAppKeyError(response);
        },

        /**
         * Handles invalid parameters. This function is page-specific.
         *
         * @param data the response data
         * @param status the response status
         * @param pageErrors the list of errors for the page
         * @param pageForm the form controller for the page
         * @param blockSave the value indicating whether the error should disable the save button
         */
        handleInvalidParameterError: function(data, status, pageErrors, pageForm, blockSave) {
            if(status === PROFILE_ERRORS.INVALID_PARAMETER_ERROR) {
                this.handleFieldError(data, status, pageErrors, pageForm, blockSave);
            }
        },

        /**
         * Handles a backend service (such as Optin) being unavailable. This function is page-specific.
         *
         * @param data the response data
         * @param status the response status
         * @param pageErrors the list of errors for the page
         * @param pageForm the form controller for the page
         * @param blockSave the value indicating whether the error should disable the save button
         */
        handleServiceUnavailableError: function(data, status, pageErrors, pageForm, blockSave) {
            if(status === PROFILE_ERRORS.SERVICE_UNAVAILABLE_ERROR) {
                this.handleFieldError(data, status, pageErrors, pageForm, blockSave)
            }
        },

        /**
         * Handles any error pertaining to a specific field on the page. This function is page-specific.
         *
         * @param data the response data
         * @param status the response status
         * @param pageErrors the list of errors for the page
         * @param pageForm the form controller for the page
         * @param blockSave the value indicating whether the error should disable the save button
         */
        handleFieldError: function(data, status, pageErrors, pageForm, blockSave) {

            var setParameterInvalid = function(pageErrors, pageForm, data) {
                pageErrors[data.item] = data;
                try {
                    pageForm[data.item].$error.server = data.message;
                    if(blockSave) {
                        pageForm[data.item].$setValidity('server',false);
                    }
                } catch(exception) {
                    // Om nom nom
                    // This keeps things from blowing up if the value in question is not on the form.
                }
            };

            if(angular.isArray(data))
            {
                for (var i = 0; i < data.length; i++) {
                    setParameterInvalid(pageErrors, pageForm, data[i]);
                }
            }
            else{
                setParameterInvalid(pageErrors, pageForm, data);
            }
        },

        /**
         * Handles a conflict error. This function is page-specific.
         *
         * @param data the response data
         * @param status the response status
         * @param pageErrors the list of errors for the page
         * @param pageForm the form controller for the page
         * @param blockSave the value indicating whether the error should disable the save button
         */
        handleConflictError: function(data, status, pageErrors, pageForm, blockSave) {
            if(status === PROFILE_ERRORS.CONFLICT_ERROR) {
                if(blockSave){
                    pageForm[data.item].$setValidity('server', false);
                }
                pageErrors[data.item] = data;
            }
        },

        /**
         * Handles a not found error. This function is page-specific.
         *
         * @param data the response data
         * @param status the response status
         * @param pageErrors the list of errors for the page
         * @param pageForm the form controller for the page
         */
        handleNotFoundError: function(data, status, pageErrors, pageForm) {
            if(status === PROFILE_ERRORS.NOT_FOUND_ERROR) {
                pageForm[data.item].$setValidity('server', false);
                pageErrors[data.item] = data;
            }
        },

        /**
         * Handles input errors from user. This function is page specific and should
         * be called from a page controller.
         *
         * @param data the response data
         * @param status the response status
         * @param pageErrors the list of errors for the page
         * @param pageForm the form controller for the page
         * @param blockSave the value indicating whether the error should disable the save button
         */
        handleInputErrors: function(data, status, pageErrors, pageForm, blockSave) {
            this.handleInvalidParameterError(data, status, pageErrors, pageForm, blockSave);
            this.handleServiceUnavailableError(data, status, pageErrors, pageForm, blockSave);
            this.handleConflictError(data, status, pageErrors, pageForm, blockSave);
            this.handleNotFoundError(data, status, pageErrors, pageForm, blockSave);
        },

        /**
         * Removes a server error from a page.
         *
         * @param pageForm the page form to remove the errors from
         * @param pageFormControl the page form control to remove the errors from
         * @param pageErrors the list of page errors
         */
        removePageError: function(pageForm, pageFormControl, pageErrors) {
            pageForm[pageFormControl].$setValidity('server', true);
            delete pageErrors[pageFormControl];
        },

        /**
         * Removes multiple server errors from a page.
         *
         * @param pageForm the page form to remove the errors from
         * @param pageFormControls the list of page form controls to remove the errors from
         * @param pageErrors the list of page errors
         */
        removePageErrors: function(pageForm, pageFormControls, pageErrors) {
            pageFormControls.forEach(function(pageFormControl){
                this.removePageError(pageForm, pageFormControl, pageErrors);
            }, this);
        },

        /**
         * Redirects to the error page.
         *
         * @param response the response
         */
        goToErrorPage: function(response) {
            $rootScope.globalError = true;

            var debugData = new Object();
            debugData.errorTime = new Date().getTime();
            debugData.errorId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            if(typeof response !== 'undefined'){
                if(typeof response.config !== 'undefined' && typeof response.config.url !== 'undefined'){
                    debugData.requestUrl = response.config.url;
                }
                if(typeof response.status !== 'undefined'){
                    debugData.responseCode = response.status;
                }
                if(typeof response.data !== 'undefined'){
                    debugData.response = JSON.stringify(response.data);
                }
            }

            $location.path('/error').search({debugData: JSON.stringify(debugData)});
        }
    };
}]);

app.factory('ProfileClientInterceptor', ["$rootScope", "ProfileErrorHandler", "$q", function($rootScope, ProfileErrorHandler, $q) {
    'use strict';

    var excludedUrls = [
        '/profile/services/ext/subscriptions',
        '/profile/services/ext/emailpreferences/token',
        '/profile-extras/services/ext/constants/companies',
	    '/profile-extras/services/ext/gmsToken',
        '/profile-whoops'
    ];

    // This is a hack to allow the unauthenticated subscribe page to simultaneously check if a user is logged in
    // and to get the user's country if (s)he is logged in.  There shouldn't be any functional impact from not
    // handling the unauthorized error here because nothing else in the application calls only this endpoint,
    // so any other page using it would encounter the same error while trying to get other user data like marketing.
    // The resulting error would then be handled in the same way, so the end result would be the same.
    var unauthorizedExcludedUrls = [
        '/profile/services/ext/users/~/addresses'
    ];

    return {
        responseError: function(response) {
            var excluded = false;
            var unauthorizedExcluded = false;

            for (var i = 0; i < excludedUrls.length; i++) {
                if (response.config.url.contains(excludedUrls[i])) {
                    excluded = true;

                    break;
                }
            }

            for (var i = 0; i < excludedUrls.length; i++) {
                if (response.config.url.contains(unauthorizedExcludedUrls[i])) {
                    unauthorizedExcluded = true;

                    break;
                }
            }

            if(!excluded && !unauthorizedExcluded) {
                ProfileErrorHandler.handleApplicationErrors(response);
            }else if(!excluded){
                ProfileErrorHandler.handleApplicationErrorsExceptUnauthorized(response);
            }

            return $q.reject(response);
        }
    };
}]);
app.factory('ProfileUserCommon', ["ProfileClient", "ProfileErrorHandler", "ProfileUtils", function(ProfileClient, ProfileErrorHandler, ProfileUtils) {
     'use strict';

     var changeCountry = function ($scope) {
          delete $scope.user.state;
          $scope.states = ProfileUtils.getCountryStates($scope.countries, $scope.user.country);
     };

     var changeLanguage = function($scope) {
          ProfileUtils.changeLanguage($scope.user.language);
          loadOrReloadScreenProperties($scope);
     };

     var getCompanies = function(search,country,partner) {
          if (search && search.trim().length > 2) {
               return ProfileClient.constants.getCompanies(search, country, partner).then(function (response) {
                    return response.data;
               });
          }

          return [];
     };

     var loadOrReloadScreenProperties = function ($scope) {
          ProfileClient.constants.getAffiliations()
               .then(function (response) { // Success
                    $scope.affiliations = response.data;
               });

          ProfileClient.constants.getCountries()
               .then(function (response) { // Success
                    $scope.countries = response.data;

                    //if country was already set, be sure to populate states list
                    if($scope.user.country) {
                         $scope.states = ProfileUtils.getCountryStates($scope.countries, $scope.user.country);
                    }
               });

          ProfileClient.constants.getLanguages()
               .then(function (response) { // Success
                    $scope.languages = response.data;

                    // determine the correct user language
                    // handle exact match (like es) or language match (like es_MX)
                    if ( $scope.user.language !== undefined) {
                         var found;
                    
                         for (var i in response.data) {
                             var item = response.data[i];
                             var lowerCaseCode = item.code.toLowerCase();

                             if ( item.code === $scope.user.language
                                     || lowerCaseCode === $scope.user.language.toLowerCase()) {
                                 found = item;
                                 break;
                             }
                             else if (item.code.replace('_', '-') === $scope.user.language
                                     || lowerCaseCode.replace('_', '-') === $scope.user.language.toLowerCase()) {
                                 found = item;
                                 break;
                             }
                             else if ( $scope.user.language.startsWith(item.code)) {
                                 found = item;
                                 // don't break, maybe we'll get an exact match
                             }
                         }
                         if ( found === undefined) {
                             $scope.user.language = undefined;
                         } else {
                             $scope.user.language = found.code;
                         }
                     }
               });

          ProfileClient.constants.getTranslatedProperties('create', 'errors')
               .then(function (response) { // Success
                    $scope.props = response.data;
                    $scope.swapFirstAndLastName = $scope.props['setting.swap.first.and.last.name'] == 'true';
                    $scope.props['text.respect.info.privacy'] = ProfileUtils.trustAsHtml($scope.props['text.respect.info.privacy']);
                    $scope.props['text.agree.terms.and.conditions'] = ProfileUtils.trustAsHtml($scope.props['text.agree.terms.and.conditions']);
                    $scope.props['text.optin.yes'] = ProfileUtils.trustAsHtml($scope.props['text.optin.yes']);
                    $scope.hasLoadedProperties = true;
               });
     };
     
     var removePageError = function($scope, form, formControl) {
          ProfileErrorHandler.removePageError(form, formControl, $scope.errors);
     };

     return {
          changeCountry: changeCountry,
          changeLanguage: changeLanguage,
          getCompanies: getCompanies,
          loadOrReloadScreenProperties: loadOrReloadScreenProperties,
          removePageError: removePageError
     };
}]);

app.controller('CreateProfileController', ["$scope", "ProfileClient", "QueryParamParser", "ProfileUtils", "$http", "$location", "ProfileErrorHandler", "$rootScope", function ($scope, ProfileClient, QueryParamParser, ProfileUtils, $http, $location, ProfileErrorHandler, $rootScope) {
    'use strict';

    //user info
    $scope.user = {};

    //constant info
    $scope.languages = [];
    $scope.states = [];
    $scope.countries = [];
    $scope.affiliations = [];
    $scope.expertise = [];
    $scope.props = {};

    $scope.userCreateFailed = false;

    $scope.errors = [];

    // hide/show the edit screen
    $scope.hasLoadedProperties = false;

    // require phone based on query param req=ph
    $scope.requirePhone = false;

    $scope.expertiseRequired = false;

    // verified account based on query param type=va
    $scope.isVerifiedAccount = false;
    $scope.finished = false;
    $scope.disableSubmitButton = false;

    $scope.createUser = function () {
        $scope.disableSubmitButton = true;

        if (!$scope.expertiseRequired) {
            delete $scope.user.expertise;
        }

        ProfileClient.user.create($scope.user)
            .then(function () { // Success
                    $scope.finished = true;
                    //$location.path('/create-activation');
                },
                function (response) { // Error
                    $scope.disableSubmitButton = false;
                    // If the returnUrl validation failed, nullify the returnUrl and try the submission again
                    if(ProfileUtils.didReturnUrlValidationFail(response.data, response.status)) {
                        if($scope.user.returnUrl != null) {
                            $scope.user.returnUrl = null;
                            $scope.createUser();
                        }
                    } else {
                        if (response.data.item && response.data.item === 'person') {
                            response.data.item = 'emailAddress';
                        }
                        ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.createProfileForm, true);
                    }
                }
            );
    };

    $scope.loadOrReloadScreenProperties = function () {
        ProfileClient.constants.getAffiliations()
            .then(function (response) { // Success
                $scope.affiliations = response.data;
            });

        ProfileClient.constants.getCountries()
            .then(function (response) { // Success
                $scope.countries = response.data;

                //if country was already set, be sure to populate states list
                if($scope.user.country) {
                    $scope.states = ProfileUtils.getCountryStates($scope.countries, $scope.user.country);
                }
            });

        ProfileClient.constants.getExpertise()
            .then(function (response) { // Success
                $scope.expertise = response.data;
            });

        ProfileClient.constants.getLanguages()
            .then(function (response) { // Success
                $scope.languages = response.data;

                // determine the correct user language
                // handle exact match (like es) or language match (like es_MX)
                if ( $scope.user.language !== undefined) {
                    var found;
                    for (var i in response.data) {
                        var item = response.data[i];
                        var lowerCaseCode = item.code.toLowerCase();

                        if ( item.code === $scope.user.language
                                || lowerCaseCode === $scope.user.language.toLowerCase()) {
                            found = item;
                            break;
                        }
                        else if (item.code.replace('_', '-') === $scope.user.language
                                || lowerCaseCode.replace('_', '-') === $scope.user.language.toLowerCase()) {
                            found = item;
                            break;
                        }
                        else if ( $scope.user.language.startsWith(item.code)) {
                            found = item;
                            // don't break, maybe we'll get an exact match
                        }
                    }
                    if ( found === undefined) {
                        $scope.user.language = undefined;
                    } else {
                        $scope.user.language = found.code;
                    }
                }

            });

        ProfileClient.constants.getTranslatedProperties('create', 'errors')
            .then(function (response) { // Success
                $scope.props = response.data;
                $scope.swapFirstAndLastName = $scope.props['setting.swap.first.and.last.name'] == 'true';
                $scope.props['text.respect.info.privacy'] = ProfileUtils.trustAsHtml($scope.props['text.respect.info.privacy']);
                $scope.props['text.agree.terms.and.conditions'] = ProfileUtils.trustAsHtml($scope.props['text.agree.terms.and.conditions']);
                $scope.props['text.optin.yes'] = ProfileUtils.trustAsHtml($scope.props['text.optin.yes']);
                $scope.hasLoadedProperties = true;
            });
    };

    $scope.changeLanguage = function () {
        ProfileUtils.changeLanguage($scope.user.language);
        $scope.loadOrReloadScreenProperties();
    };

    $scope.changeCountry = function () {
        delete $scope.user.state;
        $scope.states = ProfileUtils.getCountryStates($scope.countries, $scope.user.country);
        if ($scope.user.country === 'IN') {
            $scope.requirePhone = true;
        } else {
            $scope.requirePhone = false;
        }
    };

    $scope.changeAffiliation = function() {
        if ($scope.user.affiliation === '1000' || $scope.user.affiliation === '1023') {
            $scope.expertiseRequired = true;
        }

        else {
            $scope.expertiseRequired = false;
        }
    };

    $scope.getCompanies = function (search,country,partner) {
        if (search && search.trim().length > 2) {
            return ProfileClient.constants.getCompanies(search, country, partner).then(function (response) {
                return response.data;
            });
        }

        return [];
    };

    $scope.removePageError = function(formControl) {
        ProfileErrorHandler.removePageError($scope.createProfileForm, formControl, $scope.errors);
    };

    //initialize
    (function(){
        var queryParams = QueryParamParser.getQueryParams();

        QueryParamParser.applyLocaleIfPresent(function(locale) {
            $scope.user.language = locale;
            if(locale.contains("-")) {
                $scope.user.country = locale.split("-")[1].toUpperCase();
            }
        });

        // requires phone (store requirement)
        if (queryParams.req !== undefined && queryParams.req.includes('ph')) {
            $scope.requirePhone = true;
        }

        // verified account
        if (queryParams.type !== undefined && queryParams.type == 'va') {
            $scope.isVerifiedAccount = true;
        }

        QueryParamParser.getReturnURL(function (returnUrl) {
            $scope.user.returnUrl = returnUrl;
        }, null, null, false);

        $scope.loadOrReloadScreenProperties();
    })();
}]);

app.controller('EditProfileController', ["$scope", "ProfileClient", "QueryParamParser", "ProfileUtils", "$http", "$window", "ProfileErrorHandler", "$location", "$routeParams", function ($scope, ProfileClient, QueryParamParser, ProfileUtils, $http, $window, ProfileErrorHandler, $location, $routeParams) {
    'use strict';

    //initialize tabs
    $scope.contactInfoSelected = true;
    $scope.interestsSelected = false;
    $scope.subscriptionsSelected = false;

    //hide initial page during loading
    $scope.hasLoadedProperties = false;

    $scope.hasCalloutBanner = false;

    //user info
    $scope.user = {};
    $scope.user.info = {};
    $scope.user.marketing = {};
    $scope.user.address = {};
    $scope.user.phone = {};
    $scope.user.source = {};
    $scope.user.subscriptions = {};

    //constant info
    $scope.languages = [];
    $scope.states = [];
    $scope.countries = [];
    $scope.affiliations = [];
    $scope.expertise = [];
    $scope.jobFunctions = [];
    $scope.industries = [];
    $scope.subscriptions = [];
    $scope.props = {};
    $scope.returnUrl = "";

    $scope.expertiseRequired = false;

    $scope.userInfoGroup = ['firstName', 'lastName', 'language'];
    $scope.userAddressGroup = ['addressLine1', 'addressLine2', 'addressLine3', 'city', 'postalCode', 'country', 'state'];
    $scope.userMarketingGroup = ['company', 'industry', 'jobTitle', 'jobFunction', 'affiliation'];

    $scope.ngModelOptions = {'updateOn': 'blur'};

    $scope.errors = {};

    //promises for async call coordination
    var userMarketingPromise = null;
    var countriesPromise = null;

    $scope.getUser = function () {
        ProfileClient.user.read()
            .then(function (response) { // Success
                $scope.user.info = response.data;
            });

        userMarketingPromise = ProfileClient.user.marketing.read()
            .then(function (response) { // Success
                $scope.user.marketing = response.data;

                if ($scope.user.marketing.affiliation === '1000' || $scope.user.marketing.affiliation === '1023') {
                    $scope.expertiseRequired = true;
                }
            });

        ProfileClient.user.address.read()
            .then(function (response) { // Success
                if (response.data.hasOwnProperty(0)) {
                    $scope.user.address = response.data[0];

                    userMarketingPromise.then(function(){
                        $scope.loadSubscriptions();
                    });

                    countriesPromise.then(function(){
                        $scope.states = ProfileUtils.getCountryStates($scope.countries, $scope.user.address.country);
                    });
                }
            });

        ProfileClient.user.phone.read()
            .then(function (response) { // Success
                if (response.data.hasOwnProperty(0)) {
                    $scope.user.phone = response.data[0];
                }
            });

        ProfileClient.user.subscription.read()
            .then(function (response) { // Success
                    response.data.forEach(function(s) {
                        $scope.user.subscriptions[s.subscriptionId] = true;
                    });
                },
                function() {
                    $scope.handleSubscriptionsFailure();
                }
            );

        ProfileClient.user.optin.read()
            .then(function (response) { // Success
        	        if (response.data.hasOwnProperty(0)) {
                        $scope.user.optin = (response.data === 'true');
                    } else {
                        $scope.user.optin = null;
                    }
                },
                function (response) {
                    ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.contactInfoForm, false);
                }
            );

        ProfileClient.user.source.read()
            .then(function (response) { // Success
                $scope.user.source = response.data;
            });

    };

    $scope.updateUser = function () {
        ProfileErrorHandler.removePageErrors($scope.contactInfoForm, $scope.userInfoGroup, $scope.errors);

        return ProfileClient.user.update($scope.user.info)
            .then(function () { // Success

                },
                function (response) { // Error
		            ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.contactInfoForm, true);
	            }
            );
    };

    $scope.updateAddress = function () {
        //get and update have different param names
        $scope.user.address.addressLine1 = $scope.user.address.line1;
        $scope.user.address.addressLine2 = $scope.user.address.line2;
        $scope.user.address.addressLine3 = $scope.user.address.line3;

        ProfileErrorHandler.removePageErrors($scope.contactInfoForm, $scope.userAddressGroup, $scope.errors);

        return ProfileClient.user.address.update($scope.user.address)
            .then(function () { // Success

            },
            function (response) { // Error
                ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.contactInfoForm, true);
            });
    };

    $scope.updateMarketing = function () {
        ProfileErrorHandler.removePageErrors($scope.contactInfoForm, $scope.userMarketingGroup, $scope.errors);

        $scope.expertiseRequired = ($scope.user.marketing.affiliation === '1000' || $scope.user.marketing.affiliation === '1023');

        return ProfileClient.user.marketing.update($scope.user.marketing)
            .then(function () { // Success

                },
                function (response) { // Error
		            ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.contactInfoForm, true);
	            }
            );
    };

    $scope.updatePhone = function () {
        //get and update have different param names
        var updateRequest = {};

        updateRequest.phone = $scope.user.phone.number;
        if(typeof $scope.user.phone.type !== "undefined"){
            updateRequest.phoneType = $scope.user.phone.type;
        }

        ProfileErrorHandler.removePageError($scope.contactInfoForm, 'phone', $scope.errors);

        return ProfileClient.user.phone.update(updateRequest)
            .then(function () { // Success

                },
                function (response) { // Error
		            ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.contactInfoForm, true);
	            }
            );
    };
    
    $scope.updateOptin = function (oldValue) {
        ProfileErrorHandler.removePageError($scope.contactInfoForm, 'optin', $scope.errors);

        var $optinRequestBody = {optin: $scope.user.optin};

        return ProfileClient.user.optin.update($optinRequestBody)
            .then(function () { // Success

                },
                function (response) { // Error
		            ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.contactInfoForm, false);
		            $scope.user.optin = oldValue;
	            }
            );
    };


    $scope.handleCalloutRequestParam = function() {
        QueryParamParser.getCallout(function (calloutproperty) {
            if ($scope.props[calloutproperty]) {
                $scope.hasCalloutBanner = true;
                $scope.calloutBanner = ProfileUtils.trustAsHtml($scope.props[calloutproperty]);
            }
        });
    };

    $scope.loadOrReloadScreenProperties = function () {
        ProfileClient.constants.getAffiliations()
            .then(function (response) { // Success
                $scope.affiliations = response.data;
            });

        countriesPromise = ProfileClient.constants.getCountries()
            .then(function (response) { // Success
                $scope.countries = response.data;
            });

        ProfileClient.constants.getExpertise()
            .then(function (response) { // Success
                $scope.expertise = response.data;
            });

        ProfileClient.constants.getLanguages()
            .then(function (response) { // Success
                $scope.languages = response.data;
            });

        ProfileClient.constants.getJobFunctions()
            .then(function (response) { // Success
                $scope.jobFunctions = response.data;
            });

        ProfileClient.constants.getIndustries()
            .then(function (response) { // Success
                $scope.industries = response.data;
            });

        ProfileClient.constants.getTranslatedProperties('edit', 'errors')
            .then(function (response) { // Success
                $scope.props = response.data;
                $scope.swapFirstAndLastName = $scope.props['setting.swap.first.and.last.name'] === 'true';
                $scope.props['text.respect.info.privacy'] = ProfileUtils.trustAsHtml($scope.props['text.respect.info.privacy']);
                $scope.props['text.optin.yes'] = ProfileUtils.trustAsHtml($scope.props['text.optin.yes']);
                $scope.handleCalloutRequestParam();
            });
    };

    $scope.changeLanguage = function () {
        ProfileUtils.changeLanguage($scope.user.language);
        $scope.updateUser()
            .then(function () { // Success
                $scope.loadOrReloadScreenProperties();
            });
    };

    $scope.changeCountry = function () {
        delete $scope.user.address.state;
        $scope.states = ProfileUtils.getCountryStates($scope.countries, $scope.user.address.country);
        $scope.loadSubscriptions();
    };

    $scope.saveProfileButtonClick = function() {
        var redirectToReturnUrl = function() {

            // only send to original page if there are no errors
            if (Object.keys($scope.errors).length === 0) {
                $window.location.href = $scope.returnUrl;
            }
        };

        // PROF-1839 do not save 2x, instead wait for all events to complete
        angular.element(document.body).injector().get('$browser').notifyWhenNoOutstandingRequests(redirectToReturnUrl);
    };

    $scope.loadSubscriptions = function() {
        ProfileClient.subscriptions.all($scope.user.address.country, $scope.user.marketing.affiliation)
            .then(function (response) { // Success
                    $scope.subscriptions = response.data;
                    $scope.hasLoadedProperties = true;
                },
                function(){ // Error
                    $scope.hasLoadedProperties = true;
                    $scope.handleSubscriptionsFailure();
                }
            );
    };

    $scope.filterGeneralInterestsSubscriptions = function(subscription) {
        return subscription.subscriptionTypeCode === 'i';
    };

    $scope.filterSolutionsAndTechnologySubscriptions = function(subscription) {
        return !subscription.countryCode && (subscription.subscriptionTypeCode === 's' || subscription.subscriptionTypeCode === 't');
    };

    $scope.filterDigitalAndMagazineSubscriptions = function(subscription) {
        return !subscription.countryCode && (subscription.subscriptionTypeCode === 'd' || subscription.subscriptionTypeCode === 'm');
    };

    $scope.filterRegionalSubscriptions = function(subscription) {
        return subscription.subscriptionTypeCode === 'd' && subscription.countryCode === $scope.user.address.country;
    };

    $scope.filterSupportAndTechnologySubscriptions = function(subscription) {
        return subscription.subscriptionTypeCode === 'c';
    };

    $scope.filterIndustrySubscriptions = function(subscription) {
        return subscription.subscriptionTypeCode === 'b';
    };

    $scope.updateSubscription = function(subscriptionId) {
        if($scope.user.subscriptions[subscriptionId]) {
            ProfileClient.user.subscription.add(subscriptionId)
                .then(function() { // Success

                    },
                    function() { // Error
		                $scope.handleSubscriptionsFailure();
	                }
                );
        } else {
            ProfileClient.user.subscription.remove(subscriptionId)
                .then(function() { // Success

                    },
                    function() { // Error
		                $scope.handleSubscriptionsFailure();
	                }
                );
        }
    };

    $scope.getCompanies = function (search,country,partner) {
        if (search && search.trim().length > 2) {
            return ProfileClient.constants.getCompanies(search, country, partner).then(function (response) {
                return response.data;
            });
        }

        return [];
    };

    $scope.changePasswordClick = function() {
        $location.path('/change-password');
    };

    $scope.changeEmailClick = function() {
        $location.path('/change-email');
    };

    $scope.handleSubscriptionsFailure = function() {
        $scope.subscriptionsError = true;

        $scope.contactInfoSelected = true;
        $scope.interestsSelected = false;
        $scope.subscriptionsSelected = false;
    };


    (function () {
        QueryParamParser.applyLocaleIfPresent(function(locale) {
            $scope.user.language = locale;
        });



        if($routeParams.selectedTab) {
            if ($routeParams.selectedTab === 'interests') {
                $scope.contactInfoSelected = false;
                $scope.interestsSelected = true;
                $scope.subscriptionsSelected = false;
            }
            else if ($routeParams.selectedTab === 'subscriptions') {
                $scope.contactInfoSelected = false;
                $scope.interestsSelected = false;
                $scope.subscriptionsSelected = true;
            }
        }

        QueryParamParser.getReturnURL(function(returnUrl) {
            $scope.returnUrl = returnUrl;
        });

        $scope.loadOrReloadScreenProperties();
        $scope.getUser();
    })();
}]);
app.controller('ChangeEmailController', ["$scope", "ProfileClient", "$http", "$window", "$location", "PROFILE_ERRORS", "ProfileErrorHandler", "QueryParamParser", function ($scope, ProfileClient, $http, $window, $location, PROFILE_ERRORS, ProfileErrorHandler, QueryParamParser) {
    'use strict';

    $scope.user = {};
    $scope.email = {};
    $scope.email.new = '';
    $scope.email.retype = '';
    $scope.errors = {};
    $scope.props = {};
    $scope.hasLoadedProperties = false;
    $scope.submitButtonDisabled = false;

    $scope.finished = false;
    $scope.success = false;
    $scope.incorrectState = false;
    $scope.rateLimited = false;

    $scope.getUser = function() {
        ProfileClient.user.read()
            .then(function (response) { // Success
                $scope.user = response.data;
            });
    };

    $scope.validateEmailRetypeMatches = function() {
        if($scope.email.new !== $scope.email.retype) {
            $scope.changeEmailForm.emailRetype.$setValidity('match', false);
        } else {
            $scope.changeEmailForm.emailRetype.$setValidity('match', true);
        }
    };

    $scope.submit = function() {
        $scope.submitButtonDisabled = true;
        var changeEmailRequest = {};
        changeEmailRequest.emailAddress = $scope.email.new;

        ProfileClient.requests.changeEmail(changeEmailRequest)
            .then(function() { // Success
                    $scope.success = true;
                    $scope.finished = true;
                },
                function(response) { // Error
                    $scope.submitButtonDisabled = false;
                    ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.changeEmailForm, true);
                    if(response.status === PROFILE_ERRORS.TOO_MANY_REQUESTS_ERROR){
                        $scope.finished = true;
                        $scope.rateLimited = true;
                    }else if(response.status === PROFILE_ERRORS.UNPROCESSABLE_ENTITY_ERROR){
                        $scope.finished = true;
                        $scope.incorrectState = true;
                    }
                }
            );
    };

    $scope.cancel = function() {
        $location.path('/edit');
    };

    $scope.removePageError = function(formControl) {
        ProfileErrorHandler.removePageError($scope.changeEmailForm, formControl, $scope.errors);
    };

    (function () {
        QueryParamParser.applyLocaleIfPresent();

        ProfileClient.constants.getTranslatedProperties('changeemail', 'errors')
            .then(function (response) { // Success
                $scope.props = response.data;
                $scope.hasLoadedProperties = true;
            });

        $scope.getUser();
    })();
}]);

app.controller('ChangePasswordController', ["$scope", "ProfileClient", "ProfileErrorHandler", "QueryParamParser", function ($scope, ProfileClient, ProfileErrorHandler, QueryParamParser) {
    'use strict';

    $scope.errors = {};
    $scope.props = [];
    $scope.oldPasswordInputType = 'password';

    //hide page during initialization
    $scope.hasLoadedProperties = false;

    var constants = ProfileClient.constants.getTranslatedProperties('changepassword', 'errors')
        .then(function (response) { // Success
            $scope.props = response.data;
        });

    ProfileClient.user.read()
        .then(function (response) { // Success
            $scope.user = response.data;

            ProfileClient.user.password.getPolicy(response.data.emailAddress).then(
                function (response) {
                    $scope.passwordPolicy = response.data;
                    constants.then(function () {
                        $scope.hasLoadedProperties = true;
                    });
                },
                function (response) {
                    ProfileErrorHandler.goToErrorPage(response);
                }
            );
        });

    QueryParamParser.applyLocaleIfPresent();

    $scope.hideShowPassword = function(){
        if ($scope.oldPasswordInputType == 'password')
            $scope.oldPasswordInputType = 'text';
        else
            $scope.oldPasswordInputType = 'password';
    };
}]);
app.controller('ForgotPasswordController', ["$scope", "$location", "ProfileClient", "ProfileErrorHandler", "PROFILE_ERRORS", "QueryParamParser", "ProfileUtils", function ($scope, $location, ProfileClient, ProfileErrorHandler, PROFILE_ERRORS, QueryParamParser, ProfileUtils) {
    'use strict';

    $scope.email = '';
    $scope.errors = {};
    $scope.finished = false;
    $scope.props = {};
    $scope.hasLoadedProperties = false;
    $scope.userNotFound = false;
    $scope.returnUrl = null;
    $scope.submitButtonDisabled = false;

    $scope.change = function () {
        $scope.removePageError('idOrEmail');

        $scope.userNotFound = false;
        $scope.userInactive = false;
    };

    $scope.removePageError = function (formControl) {
        ProfileErrorHandler.removePageError($scope.forgotPasswordForm, formControl, $scope.errors);
    };

    $scope.submit = function () {
        $scope.submitButtonDisabled = true;
        ProfileClient.requests.resetPassword({idOrEmail: $scope.email, returnUrl: $scope.returnUrl})
            .then(function () { // Success
                    $scope.finished = true;
                },
                function (response) { // Error
                    $scope.submitButtonDisabled = false;
                    // If the returnUrl validation failed, nullify the returnUrl and try the submission again
                    if (ProfileUtils.didReturnUrlValidationFail(response.data, response.status)) {
                        if ($scope.returnUrl != null) {
                            $scope.returnUrl = null;
                            $scope.submit();
                        }
                    } else if (response.status == PROFILE_ERRORS.NOT_FOUND_ERROR) {
                        $scope.userNotFound = true;
                    } else if (response.status == PROFILE_ERRORS.UNPROCESSABLE_ENTITY_ERROR) {
                        $scope.userInactive = true;
                    } else {
                        ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.forgotPasswordForm, true);
                    }
                }
            );
    };

    (function () {
        QueryParamParser.applyLocaleIfPresent();

        ProfileClient.constants.getTranslatedProperties('forgotpassword', 'errors')
            .then(function (response) { // Success
                $scope.props = response.data;
                $scope.hasLoadedProperties = true;
            });

        QueryParamParser.getReturnURL(function (returnUrl) {
            $scope.returnUrl = returnUrl;
        }, null, null, false);
    })();
}]);

app.controller('OptinController', ["$scope", "ProfileClient", "QueryParamParser", "ProfileUtils", "$http", "$window", "ProfileErrorHandler", function ($scope, ProfileClient, QueryParamParser, ProfileUtils, $http, $window, ProfileErrorHandler) {
	'use strict';

	$scope.optinUser = {};

	//hide initial page during loading
	$scope.hasLoadedProperties = false;

	//constant info
	$scope.props = {};

	$scope.errors = {};

	$scope.optinText='';

	$scope.optinTitle='';

	$scope.finished=false;

	$scope.optin = function () {
		ProfileClient.emailpreferences.optin($scope.optinUser.emailAddress, $scope.optinUser)
			.then(function () { // Success
					$scope.optinTextFinish = $scope.optinTextFinish.replace('{0}', $scope.optinUser.emailAddress);
					$scope.finished = true;
				},
				function (response) { // Error
					ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.optinForm, true);

					// If invisible/non-user-supplied fields are invalid, just show the fatal error page
					if ($scope.errors.hasOwnProperty("organization") || $scope.errors.hasOwnProperty("language") || $scope.errors.hasOwnProperty("country")) {
						ProfileErrorHandler.goToErrorPage(response);
					}
				}
			);
	};

	$scope.removePageError = function(formControl) {
		ProfileErrorHandler.removePageError($scope.optinForm, formControl, $scope.errors);
	};

	(/**
	 * Initialize the application
	 */
	function () {
		QueryParamParser.applyLocaleIfPresent();

		// Organization (optional)
		if (QueryParamParser.getQueryParamsLowerCase()['organization']) {
			$scope.optinUser.organization = QueryParamParser.getQueryParamsLowerCase()['organization'];
		} else {
			// I hate to hardcode this here, but without it, properties would be much more difficult
			$scope.optinUser.organization = "sas";
		}

		// email (optional)
		if (QueryParamParser.getEmail()) {
			$scope.optinUser.emailAddress = QueryParamParser.getEmail();
		}

		// locale (optional)
		if (QueryParamParser.getLocale()) {
			var localeArray = QueryParamParser.getLocale().split('_', 2);
			$scope.optinUser.language = localeArray[0];
			if (localeArray.length == 2) {
				$scope.optinUser.country = localeArray[1];
			}
		}

		// Load the properties
		// Note: we still load properties if we have a fatal error from request params
		ProfileClient.constants.getTranslatedProperties('optin', 'errors')
			.then(function (response) {
				$scope.props = response.data;

				// Validate the organization
				ProfileClient.validation.optinOrganization($scope.optinUser.organization)
					.then(function(response) { // Success
						if (response.data !== "true") {
							ProfileErrorHandler.goToErrorPage(response);
						}
					});

				$scope.optinTitle = $scope.props['title.optin.' + $scope.optinUser.organization];
				$scope.optinText = $scope.props['text.optin.' + $scope.optinUser.organization + '.welcome'];
				$scope.optinTextFinish = $scope.props['text.optin.' + $scope.optinUser.organization + '.thankyou'];
				$scope.optinButton = $scope.props['button.optin.' + $scope.optinUser.organization];

				$scope.props['text.respect.info.privacy'] = ProfileUtils.trustAsHtml($scope.props['text.respect.info.privacy']);

				$scope.hasLoadedProperties = true;
			});
	})();
}]);
app.controller('OptinVerifyController', ["$scope", "ProfileClient", "QueryParamParser", "ProfileUtils", "$http", "$window", "ProfileErrorHandler", function ($scope, ProfileClient, QueryParamParser, ProfileUtils, $http, $window, ProfileErrorHandler) {
	'use strict';

	$scope.optinVerifyUser = {};

	//hide initial page during loading
	$scope.hasLoadedProperties = false;

	//constant info
	$scope.props = {};

	$scope.errors = {};

	$scope.optinVerifyTitle='';

	$scope.finished=false;

	$scope.fatalError=false;

	$scope.removePageError = function(formControl) {
		ProfileErrorHandler.removePageError($scope.optinVerifyForm, formControl, $scope.errors);
	};

	(/**
	 * Initialize the application
	 */
	function () {
		QueryParamParser.applyLocaleIfPresent();

		// Token
		if (QueryParamParser.getQueryParamsLowerCase()['token']) {
			$scope.optinVerifyUser.token = QueryParamParser.getQueryParamsLowerCase()['token'];
		} else {
			$scope.fatalError = true;
		}

		// Load the properties
		// Note: we still load properties if we have a fatal error from request params
		ProfileClient.constants.getTranslatedProperties('optinverify', 'errors')
			.then(function (response) {
				$scope.props = response.data;

				if (!$scope.fatalError && !$scope.invalidToken){
					ProfileClient.emailpreferences.optinVerify($scope.optinVerifyUser.token)
						.then(function(){ // Success
								$scope.finished = true;
							},
							function(response){ // Error
								ProfileErrorHandler.goToErrorPage(response);
							}
						);
				}

				$scope.optinVerifyTitle = $scope.props['title.optinVerify'];
				$scope.optinVerifyTextFinish = $scope.props['text.optinVerify.thankyou'];
				$scope.optinVerifyTextLinks = $scope.props['text.optinVerify.links'];

				$scope.props['text.respect.info.privacy'] = ProfileUtils.trustAsHtml($scope.props['text.respect.info.privacy']);

				$scope.hasLoadedProperties = true;
			});
	})();
}]);
app.controller('OptoutController', ["$scope", "ProfileClient", "QueryParamParser", "ProfileUtils", "$http", "$window", "ProfileErrorHandler", function ($scope, ProfileClient, QueryParamParser, ProfileUtils, $http, $window, ProfileErrorHandler) {
    'use strict';

    $scope.optoutUser = {};

    //hide initial page during loading
    $scope.hasLoadedProperties = false;

    //constant info
    $scope.props = {};

    $scope.errors = {};

    $scope.optoutText='';

    $scope.optoutTitle='';

    $scope.readyForProperties=true;
    $scope.finished=false;

    $scope.optout = function () {
        ProfileClient.emailpreferences.optout($scope.optoutUser.emailAddress, $scope.optoutUser)
            .then(function () { // Success
                    $scope.optoutTextFinish = $scope.optoutTextFinish.replace('{0}', $scope.optoutUser.emailAddress);
                    $scope.finished = true;
                },
                function (response) { // Error
                    ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.optoutForm, true);

                    // If invisible/non-user-supplied fields are invalid, just show the fatal error page
	                if ($scope.errors.hasOwnProperty("organization") || $scope.errors.hasOwnProperty("sourceEtsId")) {
		                ProfileErrorHandler.goToErrorPage(response);
	                }
                }
            );
    };

    $scope.removePageError = function(formControl) {
        ProfileErrorHandler.removePageError($scope.optoutForm, formControl, $scope.errors);
    };

    (/**
     * Initialize the application
     */
    function () {
        QueryParamParser.applyLocaleIfPresent();

	    // Organization (optional)
	    if (QueryParamParser.getQueryParamsLowerCase()['organization']) {
		    $scope.optoutUser.organization = QueryParamParser.getQueryParamsLowerCase()['organization'];
	    } else {
	        // I hate to hardcode this here, but without it, properties would be much more difficult
		    $scope.optoutUser.organization = "sas";
        }

        // email (optional)
        if (QueryParamParser.getEmail()) {
            $scope.optoutUser.emailAddress = QueryParamParser.getEmail();
        }

	    // verificationToken (optional)
	    if (QueryParamParser.getQueryParamsLowerCase()['token']) {
	    	$scope.readyForProperties=false;
		    ProfileClient.emailpreferences.getEmailAndOrganization(QueryParamParser.getQueryParamsLowerCase()['token']).then(function (response) { // Success
			    if(response.data.emailAddress!==undefined && response.data.organization!==undefined){
				    $scope.optoutUser.emailAddress = response.data.emailAddress;
				    $scope.optoutUser.organization = response.data.organization;
				    $scope.readyForProperties=true;
				    insertPropertiesIfReady();
			    }

			    $scope.readyForProperties=true;
			    insertPropertiesIfReady();
		    });
	    }

        // email by GMS token (optional)
	    var gmsToken = QueryParamParser.getQueryParamsLowerCase()['gmstoken'];
        if (gmsToken) {
            ProfileClient.gmsToken.getEmail(gmsToken).then(function (response) { // Success
                // Angular.js doesn't seem to recognize a quoted string as valid JSON even though it is
	            $scope.optoutUser.emailAddress = angular.fromJson(response.data);
            });
        }

	    // appId/sourceEtsId
	    if (QueryParamParser.getQueryParamsLowerCase()['appid']) {
		    $scope.optoutUser.sourceEtsId = QueryParamParser.getQueryParamsLowerCase()['appid'];
	    }

        // Load the properties
        // Note: we still load properties if we have a fatal error from request params
        ProfileClient.constants.getTranslatedProperties('optout', 'errors')
            .then(function (response) {
                $scope.props = response.data;

	            // Validate the organization
	            ProfileClient.validation.optinOrganization($scope.optoutUser.organization)
		            .then(function(response) { // Success
			            if (response.data !== "true") {
				            ProfileErrorHandler.goToErrorPage(response);
			            }
		            });

	            $scope.props['text.respect.info.privacy'] = ProfileUtils.trustAsHtml($scope.props['text.respect.info.privacy']);

	            insertPropertiesIfReady();
            });
    })();

    function insertPropertiesIfReady(){
    	if($scope.readyForProperties){
		    $scope.optoutTitle = $scope.props['title.optout.' + $scope.optoutUser.organization];
		    $scope.optoutText = $scope.props['text.optout.' + $scope.optoutUser.organization + '.welcome'];
		    $scope.optoutTextFinish = $scope.props['text.optout.' + $scope.optoutUser.organization + '.thankyou'];
		    $scope.optoutTextLinks = $scope.props['text.optout.' + $scope.optoutUser.organization + '.links'];
		    $scope.optoutButton = $scope.props['button.optout.' + $scope.optoutUser.organization];

		    $scope.hasLoadedProperties = true;
	    }
    }
}]);
app.controller('ResetPasswordController', ["$scope", "$location", "$window", "ProfileClient", "QueryParamParser", "PROFILE_ERRORS", "ProfileErrorHandler", function ($scope, $location, $window, ProfileClient, QueryParamParser, PROFILE_ERRORS, ProfileErrorHandler) {
    'use strict';

    $scope.fatalError = false;
    $scope.finished = false;
    $scope.invalidToken = false;
    $scope.incorrectState = false;
	$scope.hasLoadedProperties = false;
    $scope.props = {};
    $scope.returnUrl = "";
    $scope.token = '';
    $scope.errors = {};

    $scope.continue = function() {
        $window.location.href = $scope.returnUrl;
    };

    $scope.onError = function(response) {
        if(response.status === PROFILE_ERRORS.NOT_FOUND_ERROR) {
            $scope.invalidToken = true;
        }else if(response.status === PROFILE_ERRORS.UNPROCESSABLE_ENTITY_ERROR){
            $scope.incorrectState = true;
        }
    };

    (function () {
        $scope.token = QueryParamParser.getToken();

        QueryParamParser.applyLocaleIfPresent();

        QueryParamParser.getReturnURL(function(returnUrl) {
            $scope.returnUrl = returnUrl;
        });

        var constants = ProfileClient.constants.getTranslatedProperties('resetpassword', 'errors')
            .then(function (response) { // Success
                $scope.props = response.data;
            });

        if ($scope.token){
            ProfileClient.requests.getResetPassword($scope.token).then(
                function (response) {
                    ProfileClient.user.password.getPolicy(response.data.email).then(
                        function (response) {
                            $scope.passwordPolicy = response.data;
                            constants.then(function () {
                                $scope.hasLoadedProperties = true;
                            });
                        },
                        function (response) {
                            ProfileErrorHandler.goToErrorPage(response);
                        }
                    );
                },
                function (response) {
                    ProfileErrorHandler.goToErrorPage(response);
                }
            );
        }else{
            $scope.fatalError = true;
        }
    })();
}]);

app.controller('UnsubscribeController', ["$scope", "ProfileClient", "QueryParamParser", "ProfileUtils", "$http", "$window", "ProfileErrorHandler", function ($scope, ProfileClient, QueryParamParser, ProfileUtils, $http, $window, ProfileErrorHandler) {
    'use strict';

    $scope.unsubscribeUser = {};

    //hide initial page during loading
    $scope.hasLoadedProperties = false;

    //constant info
    $scope.props = {};

    $scope.errors = {};

    $scope.unsubscribeText='';

    $scope.subscriptionTitle='';

    $scope.finished=false;

    $scope.fatalError=false;

    $scope.unsubscribe = function () {
        ProfileClient.subscriptions.unauthUnsubscribe($scope.unsubscribeUser.emailAddress ,$scope.unsubscribeUser.subscriptionCode,$scope.unsubscribeUser)
            .then(function () { // Success
                    $scope.unsubscribeTextFinish = $scope.unsubscribeTextFinish.replace('{0}', $scope.unsubscribeUser.emailAddress);
                    $scope.finished = true;
                },
                function (response) { // Error
                    ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.unsubscribeForm, true);
                }
            );
    };

    $scope.removePageError = function(formControl) {
        ProfileErrorHandler.removePageError($scope.unsubscribeForm, formControl, $scope.errors);
    };

    (/**
     * Initialize the application
     */
    function () {
        var params = QueryParamParser.getQueryParams();

        QueryParamParser.applyLocaleIfPresent();

        // subcode (required)
        if (params.subcode !== undefined) {
            $scope.unsubscribeUser.subscriptionCode = params.subcode;
        } else {
            // missing subcode, send to error page
            $scope.fatalError=true;
        }

        // email (optional)
        if (QueryParamParser.getEmail()) {
            $scope.unsubscribeUser.emailAddress = QueryParamParser.getEmail();
        }

        // email by GMS token (optional)
        var gmsToken = QueryParamParser.getQueryParamsLowerCase()['gmstoken'];
        if (gmsToken) {
            ProfileClient.gmsToken.getEmail(gmsToken).then(function (response) { // Success
                // Angular.js doesn't seem to recognize a quoted string as valid JSON even though it is
	            $scope.unsubscribeUser.emailAddress = angular.fromJson(response.data);
            });
        }

        // Load the properties and then load the subscription
        // Note: we still load properties if we have a fatal error from request params
        ProfileClient.constants.getTranslatedProperties('unsubscribe', 'errors')
            .then(function (response) {
                $scope.props = response.data;

                // there may not be a subcode
                if ( $scope.unsubscribeUser.subscriptionCode !== undefined && !$scope.fatalError ) {
                    ProfileClient.subscriptions.forSubcode($scope.unsubscribeUser.subscriptionCode)
                        .then(function (response) {
                            // check for presence of title in response
                            if ( response == undefined || response.data == undefined || response.data.title == undefined) {
                                ProfileErrorHandler.goToErrorPage(response);
                            } else {
                                $scope.subscriptionTitle = response.data.title;
                                $scope.unsubscribeText = $scope.props['text.unsubscribe.welcome'];
                                $scope.unsubscribeText = $scope.unsubscribeText.replace('{0}', $scope.subscriptionTitle);
                                $scope.unsubscribeTextFinish = $scope.props['legacyjsp.unsubscribe.thankyou'];
                                $scope.unsubscribeTextFinish = $scope.unsubscribeTextFinish.replace('{1}', $scope.subscriptionTitle);
                                $scope.props['text.respect.info.privacy'] = ProfileUtils.trustAsHtml($scope.props['text.respect.info.privacy']);
                            }
                            $scope.hasLoadedProperties = true;
                        },function errorGettingSubscription(response) {
                            // error getting the subscription data
                            ProfileErrorHandler.goToErrorPage(response);
                        });
                } else {
                    // we're done, display the error page
                    $scope.hasLoadedProperties = true;
                }
            });
    })();
}]);

app.controller('FinishActivationController', ["$scope", "$location", "$window", "ProfileClient", "PROFILE_ERRORS", "QueryParamParser", "$routeParams", "OktaClient", "ProfileErrorHandler", function ($scope, $location, $window, ProfileClient, PROFILE_ERRORS, QueryParamParser, $routeParams, OktaClient, ProfileErrorHandler) {
    'use strict';

    $scope.errors = {};
    $scope.fatalError = false;
    $scope.finished = false;
    $scope.invalidToken = false;
    $scope.incorrectState = false;
    $scope.hasLoadedProperties = false;
    $scope.props = {};
    $scope.returnUrl = '';
    $scope.token = '';
    $scope.terms = {alreadyAcceptedTC:true, agreeTC:false};

    $scope.continue = function () {
        $window.location.href = $scope.returnUrl;
    };

    $scope.onError = function (response) {
        if(response.status === PROFILE_ERRORS.NOT_FOUND_ERROR) {
            $scope.invalidToken = true;
        }else if(response.status === PROFILE_ERRORS.UNPROCESSABLE_ENTITY_ERROR){
            $scope.incorrectState = true;
        }
    };

    $scope.activate = function () {
        ProfileClient.requests.getActivation($scope.token)
            .then(function (response) {
                if (response.data.alreadyActive) {
                    $scope.finished = true;
                }

                $scope.terms.alreadyAcceptedTC = response.data.termsAccepted;

                ProfileClient.user.password.getPolicy(response.data.email).then(
                    function (response) {
                        $scope.passwordPolicy = response.data;
                    },
                    function (response) {
                        ProfileErrorHandler.goToErrorPage(response);
                    }
                );
            }, function (response) {
                if(response.status === PROFILE_ERRORS.NOT_FOUND_ERROR) {
                    $scope.invalidToken = true;
                }else{
                    ProfileErrorHandler.goToErrorPage(response);
                }
            });
    };

    (function () {
        QueryParamParser.applyLocaleIfPresent();

        ProfileClient.constants.getTranslatedProperties('finishactivation', 'errors')
            .then(function (response) { // Success
                $scope.props = response.data;
                $scope.hasLoadedProperties = true;
            });

        QueryParamParser.getReturnURL(function (returnUrl) {
            $scope.returnUrl = returnUrl;
        });

        if ($routeParams.system === 'hosting') {
            //hosting users are activated using Okta activation process. This is just a splash screen for them. (CIDAM-289)
            //Per GIS, signing the user out of Okta since Okta activation process auto signs the user in
            OktaClient.user.signOut();
            $scope.finished = true;
        } else {
            $scope.token = QueryParamParser.getToken();
            if (!$scope.token) {
                $scope.fatalError = true;
            } else {
                $scope.activate();
            }
        }
    })();
}]);
app.controller('UnAuthSubscribeController', ["$scope", "PROFILE_ERRORS", "ProfileClient", "QueryParamParser", "ProfileUtils", "$http", "$window", "ProfileErrorHandler", function ($scope, PROFILE_ERRORS, ProfileClient, QueryParamParser, ProfileUtils, $http, $window, ProfileErrorHandler) {
    'use strict';

    $scope.subscribeUser = {};

    //hide initial page during loading
    $scope.hasLoadedProperties = false;

    //constant info
    $scope.props = {};
    $scope.countries = [];

    $scope.errors = {};

    $scope.subscribeText='';

    $scope.subscribeTextFinish ='';

    $scope.subscriptionTitle='';

    $scope.userLoggedIn=false;

    $scope.finished=false;

    $scope.fatalError=false;

    $scope.subscribe = function () {
        ProfileClient.subscriptions.unauthSubscribe($scope.subscribeUser.emailAddress ,$scope.subscribeUser.subscriptionCode,$scope.subscribeUser)
            .then(function () { // Success
                    $scope.subscribeTextFinish = $scope.subscribeTextFinish.replace('{0}', $scope.subscribeUser.emailAddress);
                    $scope.finished = true;
                },
                function (response) { // Error
                    ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.unAuthSubscribeForm, true);
                }
            );
    };

    $scope.removePageError = function(formControl) {
        ProfileErrorHandler.removePageError($scope.unAuthSubscribeForm, formControl, $scope.errors);
    };

    /**
     * Initialize the application
     */
    (function () {
        var params = QueryParamParser.getQueryParams();

        QueryParamParser.applyLocaleIfPresent(function(locale) {
            if(locale.contains("-")) {
                $scope.subscribeUser.country = locale.split("-")[1].toUpperCase();
            }
        });

        // subcode (required)
        if (params.subcode !== undefined) {
            $scope.subscribeUser.subscriptionCode = params.subcode;
        } else {
            // missing subcode, send to error page
            $scope.fatalError=true;
        }

        // email (optional)
        if (QueryParamParser.getEmail()) {
            $scope.subscribeUser.emailAddress = QueryParamParser.getEmail();
        }

        // Load the properties and then load the subscription
        // Note: we still load properties if we have a fatal error from request params
        ProfileClient.constants.getTranslatedProperties('anonymoussubscribe', 'errors')
            .then(function (response) {
                $scope.props = response.data;

                // there may not be a subcode
                if ( $scope.subscribeUser.subscriptionCode !== undefined && !$scope.fatalError ) {
                    ProfileClient.subscriptions.forSubcode($scope.subscribeUser.subscriptionCode)
                        .then(function (response) {
                            // check for presence of title in response
                            if ( response === undefined || response.data === undefined || response.data.title === undefined) {
                                ProfileErrorHandler.goToErrorPage(response);
                            } else {
                                $scope.subscriptionTitle = response.data.title;
                                $scope.subscribeText = $scope.props['legacyjsp.subscribe.welcome'];
                                $scope.subscribeText = $scope.subscribeText.replace('{0}', $scope.subscriptionTitle);
                                $scope.subscribeTextFinish = $scope.props['legacyjsp.subscribe.thankyou'];
                                $scope.subscribeTextFinish = $scope.subscribeTextFinish.replace('{1}', $scope.subscriptionTitle);
                                $scope.props['text.respect.info.privacy'] = ProfileUtils.trustAsHtml($scope.props['text.respect.info.privacy']);

                                // Read the user's address to get the country value.  If the user is not logged in, ignore.
                                ProfileClient.user.address.read()
                                    .then(function(response){ // Success, user is logged in
                                        if(response.data.hasOwnProperty(0)){
                                            $scope.subscribeUser.country = response.data[0].country;
                                            $scope.userLoggedIn = true;
                                        }
                                        $scope.hasLoadedProperties = true;

                                        // If the address retrieval (which does not redirect to the error page on unauthorized)
                                        // succeeded, we know the user is logged in and can call the user service to get the email
                                        // address safely
                                        ProfileClient.user.read()
                                            .then(function (response) { // Success
                                                $scope.subscribeUser.emailAddress = response.data.emailAddress;
                                            });
                                    },function(response){ // Failure
                                        $scope.hasLoadedProperties = true;
                                        if(response.status===PROFILE_ERRORS.UNAUTHORIZED_ERROR){ // If the response is 401, the user isn't logged in
                                            // Load the country list
                                            ProfileClient.constants.getCountries()
                                                .then(function(response){ // Success
                                                    $scope.countries = response.data;
                                                });
                                        }
                                        // Any response other than 401 indicates something is majorly broken and the error page will get displayed anyway
                                    });
                            }
                        },function (response) {
                            // error getting the subscription data
                            ProfileErrorHandler.goToErrorPage(response);
                        });
                } else {
                    // we're done, display the error page
                    $scope.hasLoadedProperties = true;
                }
            });
    })();
}]);

app.controller('ErrorController', ["$scope", "$location", "QueryParamParser", "ProfileClient", function ($scope, $location, QueryParamParser, ProfileClient) {
    'use strict';

    $scope.emailBody="";

    (function(){
        try{
            var debugData = JSON.parse(QueryParamParser.getQueryParams().debugData);

            // Make a call to the error report service with the generated ID of this error.  This call will always fail
            // (since the resource obviously doesn't exist) but it will put an entry in the server log that can be used
            // to figure out the user's IP address and what other calls may have been made.
            ProfileClient.error.reportError(debugData.errorId);

            var debugDataString = "Error time: " + debugData.errorTime;
            debugDataString += "\nError ID: " + debugData.errorId;
            debugDataString += "\nRequest URL: " + debugData.requestUrl;
            debugDataString += "\nResponse code: " + debugData.responseCode;
            debugDataString += "\nResponse: " + debugData.response;


            $scope.emailBody = "%0D%0A%0D%0A%0D%0AThe technical information below may help the developers to solve the problem:%0D%0A" + encodeURIComponent(debugDataString);
        }catch(e){
            // Om nom nom
        }

        if(!$scope.$parent.globalError) {
            $location.path('/');
        }
    })();
}]);

app.directive('passwordStrengthWidget', function(){
    return {
        restrict: 'E',
        scope: {
            password: '=',
            passwordStrength: '=',
            props: '<',
            passwordPolicy: '<',
            ProfileUtils: '@'
        },
        templateUrl: 'partials/directives/PasswordStrengthWidget.html',
        replace: true,
        controller: ["$scope", "ProfileUtils", function($scope, ProfileUtils){
            var regexSymbol = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@]/;
            var regexNumber = /\d/;
            var regexLowercase = /[a-z]/;
            var regexUppercase = /[A-Z]/;

            var regexInvalidCharacters = /--|#|%22|\\/;
            var regexUnicode = /[^\x00-\x7F]/;

            $scope.ProfileUtils = ProfileUtils;
            $scope.passwordStrength = {};
            $scope.passwordStrength.hasSymbol = false;
            $scope.passwordStrength.hasNumber = false;
            $scope.passwordStrength.hasUppercase = false;
            $scope.passwordStrength.hasLowercase = false;
            $scope.passwordStrength.meetsLength = false;
            $scope.passwordStrength.hasNoUnicode = true;
            $scope.passwordStrength.passwordIsValid = false;
            $scope.passwordStrength.retypeMatches = false;
            $scope.passwordStrength.showPasswordStrengthWidget = false;

            $scope.passwordStrength.validatePasswordMeetsCriteria = function () {
                $scope.passwordStrength.showPasswordStrengthWidget = true;
                $scope.passwordStrength.passwordIsValid = true;

                if ($scope.passwordPolicy.pastHistoricalPasswordsToExclude && $scope.props['text.password.requirements.historical.passwords']) {
                    $scope.props['text.password.requirements.historical.passwords'] =
                        $scope.props['text.password.requirements.historical.passwords'].replace('#', $scope.passwordPolicy.pastHistoricalPasswordsToExclude);
                }

                if ($scope.passwordPolicy.minLength && $scope.props['text.password.requirement.length']) {
                    $scope.props['text.password.requirement.length'] =
                        $scope.props['text.password.requirement.length'].replace('#', $scope.passwordPolicy.minLength);
                }

                if ($scope.passwordPolicy.minSymbol) {
                    $scope.passwordStrength.hasSymbol = $scope.password.new && ($scope.password.new.match(regexSymbol) || []).length >= $scope.passwordPolicy.minSymbol;
                    $scope.passwordStrength.passwordIsValid = $scope.passwordStrength.passwordIsValid && $scope.passwordStrength.hasSymbol;
                }

                if ($scope.passwordPolicy.minNumber) {
                    $scope.passwordStrength.hasNumber = $scope.password.new && ($scope.password.new.match(regexNumber) || []).length >= $scope.passwordPolicy.minNumber;
                    $scope.passwordStrength.passwordIsValid = $scope.passwordStrength.passwordIsValid && $scope.passwordStrength.hasNumber;
                }

                if ($scope.passwordPolicy.minUpperCase) {
                    $scope.passwordStrength.hasUppercase = $scope.password.new && ($scope.password.new.match(regexUppercase) || []).length >= $scope.passwordPolicy.minUpperCase;
                    $scope.passwordStrength.passwordIsValid = $scope.passwordStrength.passwordIsValid && $scope.passwordStrength.hasUppercase;
                }

                if ($scope.passwordPolicy.minLowerCase) {
                    $scope.passwordStrength.hasLowercase = $scope.password.new && ($scope.password.new.match(regexLowercase) || []).length >= $scope.passwordPolicy.minLowerCase;
                    $scope.passwordStrength.passwordIsValid = $scope.passwordStrength.passwordIsValid && $scope.passwordStrength.hasLowercase;
                }

                if ($scope.passwordPolicy.minLength) {
                    $scope.passwordStrength.meetsLength = $scope.password.new && $scope.password.new.length >= $scope.passwordPolicy.minLength;
                    $scope.passwordStrength.passwordIsValid = $scope.passwordStrength.passwordIsValid && $scope.passwordStrength.meetsLength;
                }

                if ($scope.passwordPolicy.blockUnicode) {
                    $scope.passwordStrength.hasNoUnicode = $scope.password.new && !$scope.password.new.match(regexUnicode);
                    $scope.passwordStrength.passwordIsValid = $scope.passwordStrength.passwordIsValid && $scope.passwordStrength.hasNoUnicode;
                }

                $scope.passwordStrength.hasInvalidChars = $scope.password.new && $scope.password.new.search(regexInvalidCharacters) >= 0;
                $scope.passwordStrength.passwordIsValid = $scope.passwordStrength.passwordIsValid && !$scope.passwordStrength.hasInvalidChars;

                $scope.passwordStrength.validatePasswordsMatch();
            };

            $scope.passwordStrength.validatePasswordsMatch = function () {
                $scope.passwordStrength.retypeMatches = ($scope.password.new === $scope.password.retype);
            };
        }]
    };
});

app.directive('setPassword', function () {
    return {
        restrict: 'E',
        scope: {
            activate: '<',
            errorCallback: '=',
            redirectUrl: '<',
            reset: '<',
            errors: '=',
            passwordForm: '<',
            password: '=',
            props: '<',
            passwordStrength: '<',
            token: '=',
            finished: '=',
            labelPasswordNew: '@',
            labelPasswordRetype: '@',
            ProfileUtils: '@',
            terms: '<?',
            user: '<?',
            passwordPolicy: '<'
        },
        templateUrl: 'partials/directives/SetPassword.html',
        replace: true,
        controller: ["$scope", "ProfileClient", "$location", "ProfileErrorHandler", "ProfileUtils", "$window", "PROFILE_ERRORS", function ($scope, ProfileClient, $location, ProfileErrorHandler, ProfileUtils, $window, PROFILE_ERRORS) {
            $scope.password = {};
            $scope.labelPasswordNew = $scope.labelPasswordNew || 'label.new.password';
            $scope.labelPasswordRetype = $scope.labelPasswordRetype || 'label.password.reenter';
            $scope.passwordInputType = 'password';
            $scope.ProfileUtils = ProfileUtils;
            $scope.ProfileErrorHandler = ProfileErrorHandler;

            $scope.$watch('passwordStrength.passwordIsValid', function (newValue, oldValue) {
                $scope.setPasswordForm.newPassword.$setValidity('password', !!newValue);
            });

            $scope.$watch('passwordStrength.retypeMatches', function (newValue, oldValue) {
                $scope.setPasswordForm.newPasswordRetype.$setValidity('password', !!('passwordStrength.passwordIsValid' && newValue));
            });

            $scope.handleProfileActivationRequest = function () {
                ProfileClient.requests.finishActivation($scope.token, {
                    password: $scope.password.new,
                    agreeTC: $scope.terms.agreeTC
                })
                    .then(function () { // Success
                            $scope.finished = true;
                        },
                        function (response) { // Error
                            $scope.disableSubmitButton = false;
                            if ($scope.errorCallback) {
                                ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.setPasswordForm, true);
                                $scope.errorCallback(response);
                            }
                        }
                    );
            };

            $scope.handlePasswordResetRequest = function () {
                ProfileClient.requests.finishResetPassword($scope.token, {password: $scope.password.new})
                    .then(function () { // Success
                            $scope.finished = true;
                        },
                        function (response) { // Error
                            $scope.disableSubmitButton = false;
                            if ($scope.errorCallback) {
                                if (response && response.data && response.data.item && response.data.item === 'password') {
                                    response.data.item = 'newPassword';
                                }
                                ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.setPasswordForm, true);
                                $scope.errorCallback(response);
                            }
                        }
                    );
            };

            $scope.handleChangePasswordRequest = function () {
                ProfileClient.user.password.update({password: $scope.password.old, newPassword: $scope.password.new})
                    .then(function () { // Success
                            $location.search('callout', 'text.password.was.changed.completed');
                            $location.path($scope.redirectUrl);
                        },
                        function (response) { // Error
                            $scope.disableSubmitButton = false;
                            ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.passwordForm, true);
                            ProfileErrorHandler.handleInputErrors(response.data, response.status, $scope.errors, $scope.setPasswordForm, true);
                        }
                    );
            };

            $scope.setPassword = function () {
                $scope.disableSubmitButton = true;

                if ($scope.token) {
                    if ($scope.activate) {
                        $scope.handleProfileActivationRequest();
                    }
                    else {
                        $scope.handlePasswordResetRequest();
                    }
                }
                else {
                    $scope.handleChangePasswordRequest();
                }
            };

            $scope.cancel = function () {
                $location.path($scope.redirectUrl);
            };

            $scope.hideShowPassword = function () {
                if ($scope.passwordInputType == 'password')
                    $scope.passwordInputType = 'text';
                else
                    $scope.passwordInputType = 'password';
            };
        }]
    };
});
app.directive('changePassword', function () {
    return {
        restrict: 'E',
        scope: {
            errorCallback: '=',
            redirectUrl: '<',
            props: '<',
            token: '=',
            finished: '=',
            user: '<?',
            passwordPolicy: '<'
        },
        templateUrl: 'partials/directives/ChangePassword.html',
        replace: true,
        controller: ["$scope", "ProfileUtils", "ProfileErrorHandler", function ($scope, ProfileUtils, ProfileErrorHandler) {
            'use strict';

            $scope.errors = {};
            $scope.props = [];
            $scope.oldPasswordInputType = 'password';
            $scope.ProfileUtils = ProfileUtils;
            $scope.ProfileErrorHandler = ProfileErrorHandler;

            $scope.hideShowPassword = function () {
                if ($scope.oldPasswordInputType == 'password')
                    $scope.oldPasswordInputType = 'text';
                else
                    $scope.oldPasswordInputType = 'password';
            };
        }]
    };
});
app.directive('profilePageHeader', function(){
    return {
        restrict: 'E',
        scope: {
            imageType: "@",
            parameterName: "<"
        },
        templateUrl: 'partials/directives/ProfilePageHeader.html',
        replace: true,
        transclude: true,
        controller: ["QueryParamParser", "$scope", "PROFILE_CONSTANTS", function(QueryParamParser, $scope, PROFILE_CONSTANTS) {
            $scope.sasUrl = PROFILE_CONSTANTS.SAS_URL;
            $scope.jmpUrl = PROFILE_CONSTANTS.JMP_URL;
            $scope.influitiveUrl = PROFILE_CONSTANTS.INFLUITIVE_URL;

            QueryParamParser.getLoginPageTheme(function(type) {
                if (type === 'jmp') {
                    document.body.className = "jmp";
                } else {
                    document.body.className = "sas";
                }

                $scope.imageType = type;
            });
        }]
    };
});
app.directive('profileActivationWrapper', function(){
    return {
        restrict: 'E',
        scope: {
            callout: '=',
            body: '=',
            show: '='
        },
        templateUrl: 'partials/directives/ProfileActivationWrapper.html',
        replace: true,
        controller: ["$scope", "ProfileUtils", function($scope, ProfileUtils) {
            $scope.ProfileUtils = ProfileUtils;
            $scope.hideCallout = false;
            $scope.hideBody = false;

            $scope.$watch('show', function(newValue, oldValue){
                if(newValue) {
                    if(!$scope.callout) {
                        $scope.hideCallout = true;
                    }

                    if(!$scope.body) {
                        $scope.hideBody = true;
                    }
                }
            });
        }]
    };
});